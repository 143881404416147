exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-distrikter-tsx": () => import("./../../../src/pages/distrikter.tsx" /* webpackChunkName: "component---src-pages-distrikter-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-handelsbetingelser-tsx": () => import("./../../../src/pages/handelsbetingelser.tsx" /* webpackChunkName: "component---src-pages-handelsbetingelser-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-kurv-tsx": () => import("./../../../src/pages/kurv.tsx" /* webpackChunkName: "component---src-pages-kurv-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-om-tsx": () => import("./../../../src/pages/om.tsx" /* webpackChunkName: "component---src-pages-om-tsx" */),
  "component---src-pages-opskrifter-tsx": () => import("./../../../src/pages/opskrifter.tsx" /* webpackChunkName: "component---src-pages-opskrifter-tsx" */),
  "component---src-pages-privatpolitik-tsx": () => import("./../../../src/pages/privatpolitik.tsx" /* webpackChunkName: "component---src-pages-privatpolitik-tsx" */),
  "component---src-pages-producenter-tsx": () => import("./../../../src/pages/producenter.tsx" /* webpackChunkName: "component---src-pages-producenter-tsx" */),
  "component---src-pages-smagninger-tsx": () => import("./../../../src/pages/smagninger.tsx" /* webpackChunkName: "component---src-pages-smagninger-tsx" */),
  "component---src-pages-vine-tsx": () => import("./../../../src/pages/vine.tsx" /* webpackChunkName: "component---src-pages-vine-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../../../src/templates/collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-opskrift-tsx": () => import("./../../../src/templates/opskrift.tsx" /* webpackChunkName: "component---src-templates-opskrift-tsx" */),
  "component---src-templates-producent-tsx": () => import("./../../../src/templates/producent.tsx" /* webpackChunkName: "component---src-templates-producent-tsx" */),
  "component---src-templates-product-showroom-tsx": () => import("./../../../src/templates/product-showroom.tsx" /* webpackChunkName: "component---src-templates-product-showroom-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

