import { Row } from "antd";
import styled from "@emotion/styled";
import { theme } from "./theme";
import { IProduct } from "../shop/shop.interface";
import { navigate } from "gatsby";

export const isBrowser = () => typeof window !== 'undefined';

export const formatPrice = (currency: string, value: number) => {
  return Intl.NumberFormat('da-DK', {
    currency,
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(value);
};


export const toProcent = (compare: number, price: number) => {
  const one = 100 / compare
  const precent = one * (compare - price)
  return Math.ceil(precent)
}

export const regexSlug = (text: string) => {
  if(!text) return
  return text.toLowerCase().replace('ø', 'oe').replace('ø', 'ae').replace('å', 'aa').replace(/[\s-]+/g,'-').replace(/[^\wèéòàùì\s-]/gi, '')
}

export const RowSlider = styled(Row)`
overflow-x: auto;

  /* width */
::-webkit-scrollbar {
  width: 12px;
  height: 8px;
  padding: 8px 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: ${theme.colors.greyLight.grey10};
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: ${theme.colors.greyLight.grey55};
  border-radius: 6px;
}
`

export const QuantityStatusColor = (quantity: number, available: boolean) => {
  if(quantity >= 10 || (quantity === 0 && available) ) return {color: theme.colors.brandLight.available, text: 'På lager'}
  if(quantity < 10 && quantity > 0) return {color: theme.colors.brandLight.yellow, text: 'Få på lager'}
  else return {color: theme.colors.brandLight.red, text: 'Ikke på lager'}
}

export const ProductSlug = (product: IProduct) => {
  const title = product.metafields.find((m) => m.key.toLowerCase() === 'vin_title')
  const year = product.metafields.find((m) => m.key.toLowerCase() === 'year')
  const path = title && year ? `${regexSlug(title.value.trim())}-${year.value}` : `${regexSlug(product.title)}`
  return path
}

export const NavigateToProduct = (product: IProduct) => {
  const title = product.metafields.find((m) => m.key.toLowerCase() === 'vin_title')
  const year = product.metafields.find((m) => m.key.toLowerCase() === 'year')
  title && year ? navigate(`/products/${regexSlug(title.value.trim())}-${year.value}/`) : navigate(`/products/${regexSlug(product.title)}/`)
}

export const NavigateToProductUrl = (product: IProduct) => {
  const title = product.metafields.find((m) => m.key.toLowerCase() === 'vin_title')
  const year = product.metafields.find((m) => m.key.toLowerCase() === 'year')
  return title && year ?  `/products/${regexSlug(title.value.trim())}-${year.value}/` : `/products/${regexSlug(product.title)}/`
}




export const ShareIcon = styled.img`
  height: 35px;
  width: 35px;
  cursor: pointer;
  transition: all .2s ease-in-out;
  
  &:hover {
    transform: scale(1.1);
  }
  @media(max-width: 766px) {
    height: 28px;
    width: 28px;
  
  }
  `

export const PrintPage: React.FC = () => {
  const printPage = () => {
    isBrowser() && window.print()
  }
  return (
    <ShareIcon src="/icons/print.svg" style={{cursor: 'pointer'}} onClick={() => printPage()} />
  )
}