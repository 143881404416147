import { graphql, useStaticQuery } from 'gatsby';

import HeaderComponent from './navigation';
import React, { Suspense } from 'react';
import styled from '@emotion/styled';
import useWindowWidth from '../shared/useWindowSize';
import HeaderComponentMobile from './navigation-mobile';
import { isBrowser } from '../shared/utils';

const AgeGate  = React.lazy(() => import('../shared/agegate'))
const FooterComponent  = React.lazy(() => import('./footer'))

const StyledLayout = styled.div`
    overflow-x: hidden;
    background: white;
    min-height: 100vh;
    min-width: 700px;
    @media (max-width: 700px) {
      min-width: 350px;
      max-width: 100vw;
    }
  `;

const Wrapper = styled.div`
    width: 100%;
    @media (max-width: 760px) {
      margin-top: 110px;
    }
  `;
  
type Props = {
  children: any
};

const SiteLayout: React.FC<Props> = (props) => {
  if(!isBrowser()) return null
  const data = layoutQuery();
  const footerDataNodes: ILayoutData = data.allShopifyCollection;

  const winetype = footerDataNodes.nodes.filter((n) =>
    n.metafields.find((m) => m.value.toLowerCase() === 'vinetype'),
  );
  const producent = footerDataNodes.nodes.filter((n) =>
    n.metafields.find((m) => m.value.toLowerCase() === 'producent'),
  );

  const country = footerDataNodes.nodes.filter((n) =>
    n.metafields.find((m) => m.value.toLowerCase() === 'country'),
  );

  const areas = footerDataNodes.nodes.filter((n) =>
    n.metafields.find((m) => m.value.toLowerCase() === 'area'),
  );

  const width = useWindowWidth()
  return (
    <StyledLayout>
      {width > 766 ? <HeaderComponent winetype={winetype} country={country} areas={areas} producent={producent} width={width} />:
        <HeaderComponentMobile />}
      <Wrapper>
      <Suspense fallback={<div></div>}>
        <AgeGate />
      </Suspense>
        {props.children}
      </Wrapper>

      <Suspense fallback={<div>Loading footer...</div>}>
        <FooterComponent winetype={winetype} producent={producent} country={country} areas={areas} />
      </Suspense>
    </StyledLayout>
  );
};

const StyledContent = styled.div`
  max-width: 1400px;
  min-height: calc(100vh - 80px);
  min-width: 700px;
  width: 100%;
  margin: auto;
  z-index: 15;
  @media (max-width: 1400px) {
    padding: 0 1rem;
  }
  @media (max-width: 700px) {
    min-width: unset;
    max-width: 100vw;
  }
`;

export { SiteLayout, StyledContent };

export interface ICollectionLink  {
  title: string
  metafields: {
    key: string
    value: string
  }[]
}

export const layoutQuery = () => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      allShopifyCollection {
        nodes {
          title
          metafields {
            value
            key
          }
        }
      }
    }
  `);
  return data;
};

export interface ILayoutData {
  nodes: {
    title: string;
    metafields: {
      value: string;
      key: string;
    }[];
  }[];
}
