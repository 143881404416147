import './src/components/shared/global.css'

import { CacheProvider } from '@emotion/react'
import React from 'react'
import { SiteLayout } from './src/components/layout/layout'
import { StoreProvider } from './src/context/store-context'
import { cache } from '@emotion/css'

export const onServiceWorkerUpdateReady = () => window.location.reload();

export const wrapRootElement = ({ element }) => {
  if(typeof window === `undefined`) return
  return (
    <StoreProvider>
      <CacheProvider value={cache}>
        <SiteLayout>{element}</SiteLayout>
      </CacheProvider>
    </StoreProvider>
  )
}
