import { Col, Image, Row } from 'antd'
import { IResult, IResultCollection } from '../discover.interface'
import React from 'react'
import styled from '@emotion/styled'
import { theme } from '../../shared/theme'
import { H2, H2Bold, H3Bold, TextXsmall, TextXsmallBold } from '../../shared/typography'
import { navigate } from 'gatsby'
import { StyledCol, TitleText } from '../../../pages/producenter'
import { regexSlug } from '../../shared/utils'


const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
`

const StyledImg = styled(Image)`
    border-radius: 4px;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 200px;
`

export const StyledDivider = styled.div`
height: 0px;
width: 70%;
border: 2px solid ${theme.colors.brandLight.darkred};
margin-bottom: 1rem;
margin-top: -0.5rem ;
`

type Props = {
    results: IResultCollection[]
    resetQuery: () => void
}

const SearchAllProducent: React.FC<Props> = ({ results, resetQuery }: Props) => {
    return (
        <Container>
            <H2Bold>Producenter</H2Bold>
            <StyledDivider />
            <Row justify='center' style={{width: '100%'}}>
            {results && results.length > 0 && results.map((hit: IResultCollection, key) => (
                   <StyledCol 
                     span={24}
                     key={key} 
                     style={{ height: '80px', width: '100%'}}
                     onClick={() => {navigate(`/producenter/${regexSlug(hit.title.toLowerCase())}/`); resetQuery()}}
                   >
                    <div style={{width: '100%', height: '100%', position: 'relative', display: 'flex', alignItems: 'center'}}>
                     <StyledImg  
                       src={hit.image}
                       alt={hit.title}
                       loading={"lazy"}
                    height='80px'
                    width={'120px'}
                        />
                     <label style={{fontSize: 16, paddingLeft: '8px'}}>{hit.title}</label>
                     </div>
                   </StyledCol>
            ))}
                </Row>
            {!results || results.length === 0 &&
                <H2 style={{ textAlign: 'center' }}>Din søgning gav ingen resultater :-(</H2>}
        </Container>
    )
}
export default SearchAllProducent
