import { Col, Row } from 'antd'
import { IResult } from '../discover.interface'
import React from 'react'
import styled from '@emotion/styled'
import { theme } from '../../shared/theme'
import { H2, H2Bold, H3Bold, TextXsmall, TextXsmallBold } from '../../shared/typography'
import { navigate } from 'gatsby'
import { StyledDivider } from './searchDisplayProducent'
import { useScrollLock } from '../../shared/useScrollLock'


const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
`

const StyledCol = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const ProfileAvatarContainerBase = styled.div`
height: 100px;
width: 50px;
border: 1px solid ${theme.colors.greyLight.grey5};
background-position: center;
background-size: contain;
display: flex;
justify-content: center;
margin: 0 1rem 0 0;
position: relative;
text-align: center;
`;

const StyledRow = styled(Row)`
    cursor: pointer;
    transition:  .2s ease;
    &:hover {
        transform: scale(0.95) ;
    }
`

type Props = {
    results: IResult[]
    resetQuery: () => void
}

const SearchAllProduct: React.FC<Props> = ({ results, resetQuery }: Props) => {
    const { unlockScroll } = useScrollLock();

    return (
        <Container>
            <H2Bold>Produkter</H2Bold>
            <StyledDivider />
            {results && results.length > 0 && results.map((hit: IResult, key) => {
                const ProfileAvatarContainer = styled(ProfileAvatarContainerBase)`
                    background: ${hit.image ? `url(${hit.image})` : theme.colors.greyLight.grey5};
                    background-image: url(${hit.image});
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                `;                
                return (           
                    <div style={{ width: '100%' }} key={key}>
                        <StyledRow style={{ borderBottom: `1px solid ${theme.colors.greyLight.grey15}`, padding: '.5rem 0' }} onClick={() => {navigate(hit.url); resetQuery(); unlockScroll();}} >
                            <StyledCol span={6}>
                                <ProfileAvatarContainer>
                                    {!hit.image &&
                                        <H2Bold
                                            style={{
                                                alignSelf: 'center',
                                                margin: 0,
                                                color: theme.colors.greyLight.grey25,
                                            }}
                                        >
                                            {hit.title.charAt(0)}
                                        </H2Bold>
                                    }
                                </ProfileAvatarContainer>
                            </StyledCol>
                            <StyledCol span={18}>
                                <TextXsmallBold style={{ color: theme.colors.txtLight.black, marginBottom: '5px' }}>{hit.title}</TextXsmallBold>
                                <TextXsmall style={{ color: theme.colors.txtLight.black, margin: 0 }}><strong style={{fontWeight: 'bold'}}>Producent:</strong> {hit.vendor}</TextXsmall>
                                <div style={{display: 'flex', width: '100%', flexWrap: 'wrap', marginTop: 16}}>
                                    {hit.tags && hit.tags.length > 0 && hit.tags.map((tag, key) => (
                                        <TextXsmall
                                         key={key}
                                         style={{ color: theme.colors.txtLight.black, marginRight: 4, padding: '4px 8px', background: theme.colors.greyLight.grey15, borderRadius: 5 }}>{tag}</TextXsmall>
                                    )
                                    )}
                                </div>
                            </StyledCol>
                        </StyledRow>
                    </div>
                )
            })}
            {!results || results.length === 0 &&
                <H2 style={{ textAlign: 'center' }}>Din søgning gav ingen resultater :-(</H2>}
        </Container>
    )
}
export default SearchAllProduct
