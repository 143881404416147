import React from "react";
import { connectHits } from 'react-instantsearch-dom';
import styled from "@emotion/styled";
import SearchAllProduct from "./searchDisplay/searchDisplayProducts";
import SearchAllProducent from "./searchDisplay/searchDisplayProducent";
import SearchAllArea from "./searchDisplay/searchDisplayArea";

// only display Hits when user types in SearchBox
const Hits = ({ hits, type, setQuery }: any) => {

    return (
        <div>
            {type === "product" && hits && <SearchAllProduct results={hits} resetQuery={() => setQuery('')} />}
            {type === "producent" && hits && <SearchAllProducent results={hits} resetQuery={() => setQuery('')} />}
            {type === "area" && hits && <SearchAllArea results={hits} resetQuery={() => setQuery('')} />}
        </div>
    );
}

export const CustomHits: any = connectHits(Hits);
