import { AiOutlineShoppingCart } from '@react-icons/all-files/ai/AiOutlineShoppingCart'
import React from 'react'
import { formatPrice, isBrowser } from '../shared/utils'
import { navigate } from 'gatsby'
import styled from '@emotion/styled'
import { theme } from '../shared/theme'

const Badge = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: red;
  color: white;
  font-size: 8px;
  font-weight: bold;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  right: -4px;
  height: 14px;
  min-width: 14px;
`

const StyledLink = styled.div`
  color: var(black);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  align-self: center;
`

type Props = {
  quantity: number
  width: number
  price: number
}

export const CartButton: React.FC<Props> = ({ quantity, price, width }: Props) => {
  if (!isBrowser()) return null

  const TotalPrice = styled.p`
    margin-left: 16px;
    color: black;
    align-self: center;
    margin-bottom: 0;
    font-weight: bold;
  `

  const StyledIcon = styled(AiOutlineShoppingCart)`
    height: 24px;
    width: 24px;
    color: black;
  `

  const StyledDiv = styled.div`
    height: 45px;
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    background: ${theme.colors.bgLight.grey};
    align-self: center;
    cursor: pointer;
    transition: all 0.05s ease-in;
    display: flex;
    &:hover {
      transform: scale(1.1);
    }
  `

  return (
    <StyledDiv onClick={() => navigate('/kurv')}>
      <StyledLink aria-label={`Shopping Cart with ${quantity} items`}>
        <StyledIcon />
        {quantity > 0 && <Badge>{quantity}</Badge>}
      </StyledLink>
      {width > 450 && price && price > 0 && <TotalPrice>{formatPrice('DKK', price)}</TotalPrice>}
    </StyledDiv>
  )
}
