import { Breadcrumb, Col, Row } from 'antd';
import { H1Bold, H2, H3 } from '../components/shared/typography';
import { Link, graphql, navigate } from 'gatsby';

import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import Seo from '../components/Seo';
import { StyledContent } from '../components/layout/layout';
import { regexSlug } from '../components/shared/utils';
import styled from '@emotion/styled';

export const StyledCol = styled(Col)`
  position: relative;
  display: flex;
  overflow: hidden;
  height: 200px;
  margin: 1rem;
  border-radius: 5px;
  transition: 0.2s ease;
  cursor: pointer;
  background-size: cover;
  opacity: 0.8;

  &:hover {
    transform: scale(1.02);
    opacity: 1;
  }

  @media (max-width: 800px) {
    margin: 0.75rem;
  }
  @media (max-width: 700px) {
    margin: 0.50rem;
  }
  @media (max-width: 500px) {
    margin: 0.25rem;
  }
`

export const ColImg = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
`

export const TitleText = styled(H3)`
  width: 100%;
  text-align: center;
  position: absolute;
  z-index: 10;
  background: rgb(255, 255, 255, 0.9);
  align-self: center;
  padding: 1rem;
  text-transform: uppercase;
  z-index: 20;

  @media (max-width: 950px) {
    font-size: 30px;
    line-height: 30px;
  }

  @media (max-width: 800px) {
    font-size: 25px;
    line-height: 25px;
  }

  @media (max-width: 500px) {
    font-size: 16px;
    padding: 0.2rem;
    line-height: 20px;
  }
`

const Vendor: React.FC = ({
  data: { collections }}: any) => {
  
  const allP = collections.nodes.map((c: any) => c.title)
  const fallbackDesc =  'Hos ManstedWine.dk tilbyder vi vine fra en række eksklusive vinproducenter. Vores vinproducenter er nøje udvalgt, ud fra deres erfaring, know-how, passion og selfvølgelig vinkvalitet' 
  return (
    <StyledContent style={{padding: '0 0.5rem', paddingTop: '2rem'}}> 
      <Seo
        title={'Alle producenter - Eksklusive vinproducenter'}
        description={fallbackDesc}
        image={collections?.nodes[0].image?.originalSrc}
        keywords={allP}
      />
      <H1Bold style={{width: '100%', textAlign: 'center'}}>Producenter</H1Bold>
      <Row justify='center'>
      {collections.nodes && collections.nodes.map((collection: any, key: number) => (
        <StyledCol 
          xs={24} sm={11} md={7} lg={7}
          key={key} 
          style={{ width: '100%'}}
          onClick={() => navigate(`/producenter/${regexSlug(collection.title.toLowerCase())}/`)}
        >
          <ColImg  
            image={collection.image.gatsbyImageData}
            alt={collection.title}
            imgStyle={{ objectFit: 'cover' }}/>
          <TitleText>{collection.title}</TitleText>
        </StyledCol>
      ))}
      </Row>
    </StyledContent>
  )
};
export default Vendor;

export const query = graphql`
  query {
    collections: allShopifyCollection(filter: { metafields: { elemMatch: { value: { eq: "producent" } } } }) {
      nodes {
        title
        descriptionHtml
        description
        image {
          originalSrc
          gatsbyImageData(placeholder: BLURRED, width: 500)
        }
      }
    }
  }
`;
