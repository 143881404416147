import React, { useState } from "react"

import { keyframes } from "@emotion/react"
import styled from "@emotion/styled"
import { theme } from "./theme"

const showing = keyframes`
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  50% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`

const hiding = keyframes`
  50% {
    transform: translateX(200px);
    opacity: 0;
  }
  100% {
    transform: translateX(200px);
    opacity: 0;
  }
`

const StyledToast = styled.div`
  background-color: ${theme.colors.brandLight.darkred};
  color: white;
  width: max-content;
  position: absolute;
  right: 1rem;
  top: 120px;
  padding:  0.5rem 1rem;
  font-size: 14px;
  font-weight: bold;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--space-sm);
  min-width: 100px;
  justify-content: center;
  z-index: 10;
  white-space: nowrap;

  .hiding {
  animation: ${hiding} 1s ease;
}

.showing {
  animation: ${showing} 1s ease-out;
}

`


export function Toast({ show, duration = 10, ...props}: any) {
  const [visible, setVisible] = useState(show)
  const [animation, setAnimation] = useState("")

  React.useEffect(() => {
    if (show) {
      setVisible(true)
    }
    const timeout = setTimeout(() => {
      setAnimation("")
      setVisible(show)
    }, duration)
    setAnimation(show ? showing : hiding)
    return () => clearTimeout(timeout)
  }, [show, duration])

  return visible ? (
    <StyledToast
      {...props}
    />
  ) : null
}
