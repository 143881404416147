import React from 'react'
import { connectSearchBox } from 'react-instantsearch-dom';
import styled from '@emotion/styled'
import { theme } from '../shared/theme'

const StyledInput = styled.input`
    height: 40px;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    border: none;
    outline: none;
    color: ${theme.colors.greyLight.grey55};
    transition: .2s ease;
    max-width: 500px;
    width: 100%;
    border: 2px solid ${theme.colors.greyLight.grey25};
    border-radius: 10px;

    &::placeholder {
        color: ${theme.colors.greyLight.grey25};
    }
    
    &:focus {
        border: 2px solid black;

    }

    @media (max-width: 550px) {
        font-size: 20px;
    }
`

const SearchContainer = styled.div`
position: relative ;
display: flex ;
justify-content: flex-end;
align-content:  center;
align-items:  center;
height: 110px;
`

const StyledIcon = styled.img`
    height: 24px;
    width: 24px;
    align-self: center;
    right: 8px;
     position: absolute ;
     cursor: pointer;
    @media (max-width: 550px) {
        height: 25px;
        width: 25px
    }
`

const SearchBox = ({ currentRefinement, refine, clearSearch, attribute }: any) => (
    <form noValidate action="" role="search" onSubmit={e => { e.preventDefault(); }}>
        <SearchContainer >
            <StyledIcon src={currentRefinement || attribute ? '/icons/close.svg' : '/icons/search.svg'} onClick={() => currentRefinement || attribute ? (clearSearch(), refine("")) : window.document.getElementById('searchbox')?.focus()} alt="search" />
            <StyledInput
                id='searchbox'
                key="search"
                placeholder="Hvad leder du efter?"
                type="search"
                value={currentRefinement}
                onChange={(event) => refine(event.currentTarget.value)}
            />
        </SearchContainer>
    </form>
);

export const CustomSearchBox: any = connectSearchBox(SearchBox);
