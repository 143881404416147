import { Col, Row } from 'antd'
import { Configure, Hits, Index, InstantSearch } from 'react-instantsearch-dom'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import { CustomHits } from './search-result'
import { CustomSearchBox } from './searchBar'
import algoliasearch from 'algoliasearch'
import { useScrollLock } from '../shared/useScrollLock'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { theme } from '../shared/theme'


const showing = keyframes`
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`
const Container = styled.div`
    position: fixed;
    top: 120px;
    right: 0px;
    height: calc(100vh - 120px);
    overflow: auto;
    background: white;
    width: 100vw;
    padding: 2rem;
    border-right: 1px solid black;
    animation: ${showing} 0.2s ease-out;
    -webkit-box-shadow: -10px 0px 13px -7px ${theme.colors.greyLight.grey15}, 15px 5px 15px 5px rgba(184,184,184,0); 
box-shadow: -10px 0px 13px -7px ${theme.colors.greyLight.grey15}, 15px 5px 15px 5px rgba(184,184,184,0);
`
  
type Props = {

}

const SearchComponent: React.FC<Props> = ({ }: Props) => {
    const [attributes, setAttributes] = useState()
    const [query, setQuery] = useState("")

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
          /**
           * Alert if clicked on outside of element
           */
          function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
              unlockScroll();
              setQuery('');
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
      }
    const wrapperRef = useRef(null);

    useOutsideAlerter(wrapperRef);

    const { lockScroll, unlockScroll } = useScrollLock();

    const searchClient = useMemo(
        () =>
            algoliasearch(
                process.env.GATSBY_ALGOLIA_APP_ID || "",
                process.env.GATSBY_ALGOLIA_SEARCH_KEY || "",
                {}
            ),
        []
    )

    return (
        <InstantSearch
            searchClient={searchClient}
            indexName={'Products'}
            searchState={{
                query: query
            }}
            onSearchStateChange={({ query }) => { setQuery(query); query.length > 0 ? lockScroll() : unlockScroll() }}
        >
            <Configure
                hitsPerPage={20}
                
            />
            <Row style={{ width: '100%' }}>
                <Col span={24}>
                    <CustomSearchBox clearSearch={setAttributes} attribute={attributes} />
                </Col>
            </Row>
            {query && query.length > 0 && <Container ref={wrapperRef}>
                <Row gutter={60}>
                    <Col span={12}>
                        <Index indexName="Products" >
                            <CustomHits
                                hitComponent={Hits}
                                setQuery={(value: any) => setQuery(value)}
                                type="product"
                            />                   
                        </Index>
                    </Col>
                    <Col span={8}>
                        <Index indexName="Producent" >
                            <CustomHits
                                hitComponent={Hits}
                                setQuery={(value: any) => setQuery(value)}
                                type="producent"
                            />              
                        </Index>
                    </Col>
                </Row>
            </Container>}
        </InstantSearch>
    )
}

export default SearchComponent