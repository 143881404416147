import React, { Suspense, useContext, useState } from 'react'

import { CartButton } from './cart'

import { StoreContext } from '../../context/store-context'
import { Toast } from '../shared/toast'
import { navigate } from 'gatsby'
import styled from '@emotion/styled'
import { theme } from '../shared/theme'
import useWindowWidth from '../shared/useWindowSize'
const Burger  = React.lazy(() => import('./burger'))

const HeaderContainer = styled.div`
  top: 0px;
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  background: ${theme.colors.brandLight.darkred};
  z-index: 999;

  @media (max-width: 760px) {
    position: fixed;
    box-shadow: ${theme.effects.shadow01};
  }
`

const InnerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  width: 100%;
  height: 100%;
  background: ${theme.colors.bgLight.white};
  margin-top: 40px;
  @media (max-width: 900px) {
    margin-top: 0;
  }
`

const HeaderContent = styled.div`
  display: flex;
  height: 110px;
  width: 100%;
  z-index: 50;
`

const Logo = styled.img`
  width: 310px;
  align-self: center;
  margin-right: 2rem;
  height: auto;
  border-radius: 5px;
  background: white;
  cursor: pointer;

  @media (max-width: 1000px) {
    width: 250px;
  }

  @media (max-width: 495px) {
    width: 150px;
  }
`

const Icons = styled.img`
  width: 24px;
  height: 24px;
  align-self: center;
  margin-right: 1rem;
  cursor: pointer;
  transition: 0.1s ease-in;

  &:hover {
    transform: scale(1.2);
  }
`

type Props = {
}

const HeaderComponentMobile: React.FC<Props> = ({}: Props) => {
  const { checkout, loading, didJustAddToCart } = useContext(StoreContext)
  const [showBurger, setShowBurger] = useState<boolean>(false)
  const items: any = checkout ? checkout.lineItems : []

  const quantity = items.reduce((total: number, item: any) => {
    return total + item.quantity
  }, 0)

  const width = useWindowWidth()


  return (
    <HeaderContainer>
      <Suspense fallback={<div></div>}>
        <Burger isOpen={showBurger} onClose={() => setShowBurger(!showBurger)} />
      </Suspense>
      <InnerHeader>
        <HeaderContent>
          <Icons src={showBurger ? '/icons/close.svg' : '/icons/menu.svg'} onClick={() => setShowBurger(!showBurger)} alt="Menu mansted wine" />
        </HeaderContent>
        <HeaderContent>
          <Logo
            src={'/logo/logo.jpg'}
            onClick={() => navigate('/')}
            alt="Mansted wine logo"
            width={486}
            height={100}
          />
        </HeaderContent>
        <HeaderContent style={{ justifyContent: 'flex-end' }}>
          <CartButton width={width} quantity={quantity} price={checkout.totalPriceV2?.amount || null} />{' '}
          <Toast show={loading || didJustAddToCart}>
            {!didJustAddToCart ? (
              'Opdaterer…'
            ) : (
              <>
                Tilføjet til kurv{' '}
                <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.019 10.492l-2.322-3.17A.796.796 0 013.91 6.304L6.628 9.14a1.056 1.056 0 11-1.61 1.351z" fill="#fff" />
                  <path d="M5.209 10.693a1.11 1.11 0 01-.105-1.6l5.394-5.88a.757.757 0 011.159.973l-4.855 6.332a1.11 1.11 0 01-1.593.175z" fill="#fff" />
                  <path d="M5.331 7.806c.272.326.471.543.815.163.345-.38-.108.96-.108.96l-1.123-.363.416-.76z" fill="#fff" />
                </svg>
              </>
            )}
          </Toast>
        </HeaderContent>
      </InnerHeader>
    </HeaderContainer>
  )
}
export default HeaderComponentMobile
